<template>
	<div class="addressSearchContainer">
		<TnTransitionAutoHeight showIf>
			<section>
				<TransitionGroup
					name="slide-fade"
					mode="out-in"
					tag="span"
					@before-enter="beforeEnter"
					@after-leave="afterLeave"
				>
					<StandaloneAddressSearch
						v-if="currentActiveComponent === 'addressSearch'"
						class="standaloneAddressSearchContainer"
						:class="{ isEntering: isEntering && currentActiveComponent === 'addressSearch' }"
						@suggestionSelected="handleSelection"
						@changeComponent="handleChange"
						:propertySearchExcluded="content.excludePropertySearch"
						:dark="dark"
						:content="content"
						size="m"
						key="addressSearch"
					/>

					<ApartmentPicker
						v-if="currentActiveComponent === 'apartmentPicker'"
						@searchOffer="goToResults"
						@changeComponent="handleChange"
						:dark="dark"
						:content="content"
						key="apartmentPicker"
						:class="{ isEntering: isEntering && currentActiveComponent === 'apartmentPicker' }"
					/>
					<AddressPropertySearch
						v-if="currentActiveComponent === 'propertySearch'"
						@changeComponent="handleChange"
						@addressFound="handleSelection"
						:dark="dark"
						key="propertySearch"
						:class="{ isEntering: isEntering && currentActiveComponent === 'propertySearch' }"
					/>
				</TransitionGroup>
			</section>
		</TnTransitionAutoHeight>
	</div>
</template>
<script>
import GizmoComponentMixin from "~/mixins/GizmoComponentMixin.js";
import FlowMixin from "~/mixins/FlowMixin.js";
import { mapGetters, mapMutations } from "vuex";

export default defineNuxtComponent({
	name: "GizmoAddressSearch",
	mixins: [GizmoComponentMixin, FlowMixin({ disableAutoInitGraph: true })],

	props: {
		embedded: {
			type: Boolean,
			default: false,
		},
	},

	data() {
		return {
			currentActiveComponent: null,
			isEntering: false,
		};
	},
	created() {
		this.currentActiveComponent = this.addressType === "M" ? "propertySearch" : "addressSearch";
	},
	mounted() {
		if (this.content.followFlow) {
			this.initGraph();
		}
	},

	computed: {
		...mapGetters("address", ["address", "apartments", "addressType"]),
		...mapGetters("fixedOrder/fixedOrderFlow", ["isCaptchaVerified", "isUserAuthenticated"]),
		...mapGetters("ecommerce", ["segment"]), // Used for fallback if the "search result URL" is not set
	},

	methods: {
		...mapMutations("address", ["setData"]),
		handleSelection() {
			if (this.content.excludeApartmentPicker) {
				this.goToResults(this.address);
			} else if (this.apartments.length > 1) {
				this.handleChange("apartmentPicker");
			} else {
				this.goToResults(this.address);
			}
		},
		handleChange(componentName) {
			this.currentActiveComponent = componentName;
		},
		goToResults(address) {
			this.track();
			this.setData(address);
			if (this.embedded) {
				this.$emit("update:modelValue", address);
			} else if (this.content.followFlow) {
				this.$router.push({
					path: this.nextAction,
					query: {
						addressId: address.addressId,
					},
				});
			} else if (this.content.searchResultsUrl) {
				this.$router.push(`${this.content.searchResultsUrl}?addressId=${address.addressId}`);
			} else if (this.segment === "bedrift") {
				this.$router.push(`/bedrift/internett/fiber/fibersok/resultat/?addressId=${address.addressId}`); // Fallback
				console.error("Had to use fallback URL for bedrift fibersøk");
			} else {
				if (this.isCaptchaVerified || this.isUserAuthenticated) {
					this.$router.push(`/privat/internett/bestill/tilbud/?addressId=${address.addressId}`);
				} else {
					this.$router.push(`/privat/internett/bestill/captcha/?addressId=${address.addressId}`);
				}
			}
		},
		track() {
			try {
				if (window?.s?.pageName) {
					window?.utag?.link({
						event_name: "TV address search selected",
						events: { event131: 1 },
						eVar50_PageName: window.s.pageName,
					});
				}
			} catch (e) {
				console.error(e);
			}
		},
		beforeEnter() {
			this.isEntering = true;
		},
		afterLeave() {
			this.isEntering = false;
		},
	},
});
</script>

<style lang="scss" scoped>
.addressSearchContainer {
	width: 100%;
	.isEntering {
		position: absolute;
	}
	.standaloneAddressSearchContainer:deep() {
		h1,
		h2,
		h3,
		p {
			margin-bottom: $spacing-xs;
		}
		.ctaButtons {
			display: flex;
			gap: $spacing-m;
			flex-wrap: wrap;
			@include breakpoint(mobile) {
				gap: $spacing-s;
			}
		}
	}
}
.slide-fade-leave-active {
	transition-property: all;
	transition-timing-function: ease-in-out;
	transition-duration: 100ms;
	pointer-events: none;
}

.slide-fade-enter-active {
	transition: all 200ms ease-in-out 200ms;
	pointer-events: none;
}

.slide-fade-leave-to {
	transform: translateY(20px);
	opacity: 0;
}

.slide-fade-enter-from {
	transform: translateY(-20px);
	opacity: 0;
}
</style>
